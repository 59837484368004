
.scape-manager {
  position: relative;
  top: -40vh;
  width: 100%;
  perspective: 40rem;
  transform-style: preserve-3d;
}

.object {
  left: 50%;
  top: 50%;
  position: absolute;
  transform-style: preserve-3d;
}
