@import url('https://fonts.googleapis.com/css2?family=Macondo&display=swap');

.stripe {
  position: relative;
  height: 100%;
  width: 12rem;
  margin-left: 15%;
  background-color: #d8d8d8;
}

.stripe-contents {
  position: absolute;
  width: 100%;
  top: 10vh;
}

.stripe-logo {
  font-family: 'Macondo', cursive;
  color: white;
  text-align: center;
  background-color: #C3C3C3;
  font-size: 6rem;
  padding-top: .5rem;
  border-radius: .5rem;
}

.controls-toggle {
  font-family: 'Macondo', cursive;
  color: white;
  text-align: center;
  margin-top: .5rem;
  font-size: 2.3rem;
  cursor: pointer;
  background-color: initial;
  border: none;
  outline: 0;
  color: white;
  width: 100%;
}
