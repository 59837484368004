
html, body {
  height: 100%
}

body {
  /* background: #ebe9e7; */
  background-color: #E9E9E9;
  margin: 0;
}

.root {
  height: 100%;
}
