@import url(https://fonts.googleapis.com/css2?family=Macondo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Macondo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Macondo&display=swap);
.stripe {
  position: relative;
  height: 100%;
  width: 12rem;
  margin-left: 15%;
  background-color: #d8d8d8;
}

.stripe-contents {
  position: absolute;
  width: 100%;
  top: 10vh;
}

.stripe-logo {
  font-family: 'Macondo', cursive;
  color: white;
  text-align: center;
  background-color: #C3C3C3;
  font-size: 6rem;
  padding-top: .5rem;
  border-radius: .5rem;
}

.controls-toggle {
  font-family: 'Macondo', cursive;
  color: white;
  text-align: center;
  margin-top: .5rem;
  font-size: 2.3rem;
  cursor: pointer;
  background-color: transparent;
  background-color: initial;
  border: none;
  outline: 0;
  color: white;
  width: 100%;
}

.controls {
  position: relative;
  background-color: #C3C3C3;
  height: 100%;
  animation: showControls 1s forwards;
  z-index: 1;
  width: 25%;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.controls-initial {
  margin-right: 0;
  animation: none;
}

.controls-hidden {
  animation: hideControls 1s forwards;
}

.controls-removed {
  display: none;
}

@keyframes showControls {
  from {margin-right: -25%;}
  to {margin-right: 0;}
}

@keyframes hideControls {
  from {margin-right: 0;}
  to {margin-right: -25%;}
}

.controls-contents {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.control {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 5rem;
}

.control-size {
  margin-top: 0;
}

.control-label {
  text-align: center;
  font-size: 1.8rem;
  font-family: "Macondo", cursive;
  color: white;
  margin-bottom: .75rem;
}

.controls-reset {
  text-align: center;
  font-size: 1.8rem;
  font-family: "Macondo", cursive;
  color: white;
  margin-top: 5rem;
  cursor: pointer;
  background-color: transparent;
  background-color: initial;
  border: none;
  outline: 0;
}

.rc-slider-handle {
  width: 1rem;
  height: 1rem;
  margin-top: -.43rem;
  margin-right: -.5rem;
  cursor: pointer;
}

.scape-manager {
  position: relative;
  top: -40vh;
  width: 100%;
  perspective: 40rem;
  transform-style: preserve-3d;
}

.object {
  left: 50%;
  top: 50%;
  position: absolute;
  transform-style: preserve-3d;
}

.app {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.viewport {
  display: relative;
  flex: 1 1;
  height: 100%;
}

.guide-wrapper {
  position: relative;
  width: 100%;
}

.guide {
  position: absolute;
  bottom: 25vh;
  width: 100%;
  text-align: center;
  font-family: "Macondo", cursive;
  font-size: 2.8rem;
  animation: fadeAway 5s forwards;
}

@keyframes fadeAway {
  0% {opacity: 1;}
  80% {opacity: 1;}
  100% {opacity: 0;}
}


html, body {
  height: 100%
}

body {
  /* background: #ebe9e7; */
  background-color: #E9E9E9;
  margin: 0;
}

.root {
  height: 100%;
}

